<template>
  <div :style="cssVariablesBinding" data-x="html" data-x-toggle="html-overflow-hidden">
    <Theme7BaseTheHeader />

    <div v-if="shouldShowComponent" id="msgboxxWidget" />

    <main>
      <div class="tw-flex tw-flex-wrap tw-gap-10 tw-px-5 tw-py-10">
        <div class="tw-flex tw-flex-col tw-gap-4">
          <h1>HELLO</h1>
          <h2>Hello world</h2>
          <h3>Hello world</h3>
          <h4>Hello world</h4>
          <h5>Hello world</h5>
          <h6>Hello world</h6>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, alias odit earum sapiente illum quasi
            excepturi dolorum assumenda ab consectetur? Dolorum eaque ab magnam ipsam, quo fugiat, impedit, culpa maxime
            soluta voluptas reiciendis iure eligendi qui dicta inventore laudantium. Nisi libero voluptates laboriosam
            commodi architecto tempore harum minima nihil ex!
          </p>
          <small>This is small</small>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4">
          <button class="n-primary">TEST BUTTONS</button>
          <button class="n-secondary">TEST BUTTONS</button>
          <button class="n-tertiary">TEST BUTTONS</button>
          <button class="n-quaternary">TEST BUTTONS</button>
          <button class="n-primary outlined">TEST BUTTONS</button>
          <button class="n-secondary outlined">TEST BUTTONS</button>
          <button class="n-tertiary outlined">TEST BUTTONS</button>
          <button class="n-quaternary outlined">TEST BUTTONS</button>
        </div>
      </div>
      <slot />
    </main>

    <Theme7BaseTheFooter />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme7 from '~/components/theme7/AvailableComponentsForTheme7.vue';
import { useAppMode } from '~/composables';

export default defineNuxtComponent({
  name: 'Theme7',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme7 },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  head(context) {
    const appMode = useAppMode();

    if (appMode.server && context.ssrContext) {
      const websiteStore = useWebsiteStore();
      const isCopeAndCo = websiteStore.companyKey === 'copeandco';
      const tagScripts = (() => {
        if (process.env.APP_STAGE === 'production') {
          return [
            ...websiteStore.googleTagsScripts,
            {
              key: 'company-analytics',
              type: 'text/javascript',
              src: `/theme7/js/${websiteStore.companyKey}.js`,
              async: false,
            },
          ];
        }
        return [];
      })();

      return {
        link: [
          {
            rel: 'stylesheet',
            href: `/theme7/css/theme/main.css`,
            type: 'text/css',
          },
          {
            rel: 'stylesheet',
            href: `/theme7/css/theme/vendors.css`,
            type: 'text/css',
          },
          {
            rel: 'stylesheet',
            href: `/theme7/css/${websiteStore.companyKey}/${websiteStore.companyKey}.css`,
            type: 'text/css',
            fetchPriority: 'low',
          },

          isCopeAndCo
            ? {
                rel: 'stylesheet',
                href: 'https://cdn.msgboxx.io/static/css/bundle.min.css',
                type: 'text/css',
              }
            : null,
        ],
        script: [
          ...tagScripts,
          isCopeAndCo
            ? {
                src: 'https://cdn.msgboxx.io/static/js/bundle.min.js',
                async: true,
                onload: "window.msgboxxClient='COPEANDCO';",
                body: true,
              }
            : null,
          isCopeAndCo
            ? {
                src: `https://assets.calendly.com/assets/external/widget.js`,
                type: 'text/javascript',
                async: true,
              }
            : null,
          {
            src: `/theme7/js/theme/vendors.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme7/js/theme/main.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme7/js/${websiteStore.companyKey}/main.js`,
            type: 'text/javascript',
            async: false,
          },
        ],
      };
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),

    cssVariablesBinding() {
      return {
        ...this.cssVariables,
        '--c-primary': this.colors.primary || '#1B3B47',

        '--akya-text-color': 'var(--c-text-primary)',
        '--akya-background-color': 'var(--c-section_primary)',
        '--akya-confirm-button-text-color': 'var(--c-text-secondary)',
        '--akya-confirm-button-background-color': 'var(--c-primary)',
      };
    },

    isCopeAndCo() {
      /* return useWebsiteStore().companyKey === 'copeandco'; */
      return false;
    },

    shouldShowComponent() {
      const currentPath = this.$route.path;
      const isInHeadsUpAlerts = currentPath.startsWith('/heads-up-alerts');
      return this.isCopeAndCo && !isInHeadsUpAlerts;
    },
  },
});
</script>
